import viewMixin from './view-mixin'
//import { validationMixin } from 'vuelidate'
import { required, requiredIf, requiredUnless, email, minValue, maxValue, numeric, minLength, maxLength, between } from 'vuelidate/lib/validators'
import { get, isArray, isObject } from 'lodash'
//import * as validators from 'vuelidate/lib/validators'

const ENABLED = {
  RULES: { required, requiredIf, requiredUnless, email, min: minLength, max: maxLength, between, numeric, minNumber: minValue, maxNumber: maxValue },
  MESSAGES: { required: 'required', required_if: 'requiredIf', requiredUnless: 'required_unless', email: 'email', min: 'string', max: 'string', between: 'numeric', minNumber: 'numeric', maxNumber: 'numeric' }
}

export default {
  //mixins: [viewMixin, validationMixin],
  mixins: [viewMixin],
  computed: {
    isFormValid() {
      return !this.$v.form.$invalid && !this.duplicates
    },
    isFormDirty() {
      return this.$v.form.$anyDirty
    },
    // TODO: Prepare errors for all fields/validations just check if error then read prepared message
    vuelidateErrors() {
      const val = this.$v.form
      const errors = {}
      for (const param of val.$flattenParams()) {
        const combined = param.path.join('.') // + (param.path.length > 1 ? `.${param.name}` : '')
        if (!get(val, combined + `.${param.name}`, false)) {
          if (!errors[combined]) errors[combined] = []
          errors[combined].push({ name: param.name, message: this.getMessage(combined, param.name, param.params) })
        }
      }
      return errors
    },
    showErrors() {
      return { ...this.backendErrors, ...this.vuelidateErrors }
    }
  },
  data() {
    return {
      form: {},
      messages: {},
      tempGroup: [],
      fieldRules: {},
      backendErrors: {},
      validationGroup: false
    }
  },
  validations() {
    return {
      form: this.fieldRules,
      validationGroup: this.validationGroup
    }
  },
  methods: {
    parseView() {},
    parseValidator(validations, messages) {
      if (messages) this.$messages.set(messages)
      this.fieldRules = this.parseValidations(validations)
      this.$v.form.$reset()
    },
    parseValidations(validations) {
      const fieldRules = {}
      for (const name in validations) {
        let rules = validations[name]
        if (typeof rules === 'string') {
          rules = rules.split('|')
          const pos = rules.indexOf('array')
          if (pos >= 0) {
            rules.splice(pos, 1)
            fieldRules[name] = { ...(fieldRules[name] || {}), $each: this.parseFieldRules(rules) }
          } else {
            fieldRules[name] = { ...(fieldRules[name] || {}), ...this.parseFieldRules(rules) }
          }
        } else if (isArray(rules)) {
          // Run before object
          for (const related in rules) {
            fieldRules[name] = { ...(fieldRules[name] || {}), $each: this.parseValidations(rules[related].data) }
          }
        } else if (isObject(rules)) {
          for (const related in rules) {
            fieldRules[name] = { ...(fieldRules[name] || {}), [related]: { data: this.parseValidations(rules[related].data) } }
          }
        } else {
          console.error('- Parse Validations - ', name, rules)
        }
      }
      return fieldRules
    },
    parseFieldRules(rules) {
      let fieldRules = {}
      const enabled = ENABLED.RULES
      for (let conditions of rules) {
        conditions = conditions.split(':')
        let rule = this.validateRule(conditions[0])
        conditions.shift()
        /*if (['requiredIf', 'requiredUnless'].indexOf(rule) >= 0) {
          const params = conditions[0].split(',')
          fieldRules = { ...(fieldRules || {}), ...{ [rule]: enabled[rule](model => model[params[0]] == params[1]) } }
        } else {*/
        if (rules.indexOf('numeric') >= 0 && (rule === 'min' || rule === 'max')) rule += 'Number'
        if (enabled[rule]) fieldRules = { ...(fieldRules || {}), ...{ [rule]: conditions.length ? enabled[rule](...conditions[0].split(',')) : enabled[rule] } } // TODO: check split
        //}
      }
      return fieldRules
    },
    validateRule(rule) {
      switch (rule) {
        case 'simple_array':
          return 'array'
        case 'required_if':
          return 'requiredIf'
        case 'required_unless':
          return 'requiredUnless'
      }
      return rule
    },
    injectValidator(extra, id) {
      const rules = this.parseValidations({ [extra]: id ? { [id]: this.validators.extra[extra][id] } : this.validators.extra[extra] })
      this.$set(this.fieldRules, extra, rules[extra])
      //this.$v.form.$touch()
    },
    removeValidator(extra) {
      this.$set(this.fieldRules, extra, {})
      //this.$v.form.$touch()
    },
    toggleSaveButton(action, disabled) {
      this.$store.dispatch('setTopActionsStatuses', { action: (this.form.id ? 'Edit' : 'Create') + action, index: 0, disabled })
    },
    isValid(field) {
      let val = this.$v.form
      for (const attr of field.split('.')) val = val[attr] || []
      if (this.backendErrors[field]) return false
      if (!val) return true
      if (!val.$anyDirty || !val.$anyError || !val.$dirty || !val.$error) return true // Not submitted yet
      if (val.$dirty && val.$error) return false
      return true
    },
    clearErrors(field) {
      delete this.backendErrors[field]
      field = field.name || field.id || false
      if (field && this.$v.form[field]) this.$v.form[field].$touch()
      this.$v.form.$touch()
      this.parseView()
    },
    getMessage(field, name, params) {
      // Improve this
      /*switch (name) {
        case 'requiredIf':
          name = 'required' //_if'
          break
        case 'requiredUnless':
          name = 'required' //_unless'
          break
      }*/
      let message = this.$messages?.validation ? this.$messages.validation[name] : false
      if (typeof message !== 'string') {
        if (isObject(message)) {
          const type = ENABLED.MESSAGES[params.type.replace('Length', '')] || ''
          message = message[type] || ` - parsing pending - 1 ${params.type}: ${type}: ${name} - `
          Object.keys(params).forEach(key => {
            message = message.replace(`:${key}`, params[key])
          })
        } else {
          message = false
        }
      }

      const field_name = field
        .replace(/\.\$each\.[0-9]*/, '')
        .split('.')
        .pop()
      //console.log(name, field, field_name)
      return (message || this.$t('The :attribute field is not valid')).replace(':attribute', this.$t(this._humanize(field_name)))
    },

    extractNumber(value) {
      return parseFloat(('' + (value || '')).replace(/\D/g, ''))
    },

    stripNumbers(object, fields) {
      for (const field of fields) {
        if (object[field]) object[field] = this.extractNumber(object[field] || '')
      }
      return object
    },

    beforeSubmit(options) {
      if (options.numbers) {
        for (const _number of options.numbers) {
          //this.$set(this.form, _number, ('' + (this.form[_number] || '')).replace(/\D/g, ''))
          this.form[_number] = ('' + (this.form[_number] || '')).replace(/\D/g, '') // TODO: replace stripNumbers
        }
      }
      if (options.booleans) {
        for (const _boolean of options.booleans) {
          //this.$set(this.form, _boolean, this.form[_boolean] ? 1 : 0)
          this.form[_boolean] = this.form[_boolean] ? 1 : 0
        }
      }
    },

    // TODO: Dynamic errors ?
    getErrors(field) {
      const errors = this.showErrors[field]
      return isArray(errors) ? errors : []
    },
    setErrors(errors) {
      this.backendErrors = {}
      for (const field in errors) {
        this.backendErrors[field] = []
        for (const error of errors[field]) {
          this.backendErrors[field].push({ name: '', message: error })
        }
      }
    },

    // Tabs Helpers

    findElement(el, find) {
      //console.log(el.$attrs.name, find, el.$attrs.name === find)
      if (el.$attrs.name === find) return true
      if (el.$children.length) {
        for (const child of el.$children) {
          return this.findElement(child, find)
        }
      }
      return false
    },

    mapElement(el) {
      //console.log(el.$attrs.name, find, el.$attrs.name === find)
      let elements = []
      if (!!el.$attrs.name) elements.push(el.$attrs.name)
      for (const child of el.$children) {
        elements = [...elements, ...this.mapElement(child)]
      }
      return elements
    },

    locateErrors(alert) {
      if (!_.isEmpty(this.showErrors)) {
        const tabs_map = {}
        for (const ref in this.$refs) {
          if (ref.indexOf('_tab')) {
            //console.log(ref)
            //console.log(this.$refs[ref].$children)
            let elements = []
            if (this.$refs[ref].$children) {
              for (const child of this.$refs[ref].$children) {
                elements = [...elements, ...this.mapElement(child)]
              }
            }
            tabs_map[ref] = elements
            if (this.tabs_errors) this.tabs_errors[ref] = false
          }
        }

        let first = true
        for (const error in this.showErrors) {
          let idx = 0
          for (const tab in tabs_map) {
            if (tabs_map[tab].indexOf(error) >= 0) {
              if (first) {
                first = false
                this.active_tab = idx
                const el = document.getElementsByName(error)
                this.$nextTick(() => {
                  el && el[0].scrollIntoView({ behavior: 'smooth', block: 'end' })
                })
              }
              this.tabs_errors[tab] = true
              //console.log('ERROR IN TAB', tab)
            }
            idx++
          }
        }
        this.showAlert(alert || 'There was an error.', 'danger')
        return true
      }
      return false
    }
  }
}
